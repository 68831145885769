import { RequestConfig } from '../types';
import { HTTPMethods, TokenType } from '../types/enums';
import { getCookies, removeCookie, removeCookies, setCookies } from '../utils';

export class APIService {
  private baseURL: string = `${import.meta.env.VITE_API_URL}`;
  private token: string | undefined;
  public origin: string = localStorage.getItem('origin') ?? 'webapp';

  constructor() {
    removeCookie('accessToken');
  }

  private async setAuthorization(config: RequestConfig, tokenType: TokenType) {
    if (tokenType === TokenType.REFRESH) {
      const { refreshToken } = getCookies();
      if (!refreshToken) return;

      this.token = refreshToken;
    } else if (!this.token && tokenType === TokenType.ACCESS)
      await this.accessToken();

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${this.token}`,
    };

    return config;
  }

  private async accessToken(): Promise<void> {
    const { accessToken, refreshToken } = getCookies();

    if (accessToken) {
      this.token = accessToken;
      return;
    }

    if (!refreshToken) return;

    this.token = refreshToken;

    const data = await this.request<any>(
      `/auth/accessToken/${this.origin}`,
      HTTPMethods.POST,
      true,
      undefined,
      TokenType.REFRESH,
      3,
    );

    switch (data.status) {
      case 200: // Success
        /* Set accessToken JWT to cookies */
        setCookies('accessToken', data.accessToken);
        this.token = data.accessToken;
        break;
      case 498: // Token Expired/Invalid
        /* Log out user from OAuth and remove JWTs from cookies */
        removeCookies();
        break;
      default: // Other error
        break;
    }
  }

  public async get<T>(
    endpoint: string,
    auth: boolean = true,
    tokenType: TokenType = TokenType.ACCESS,
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      HTTPMethods.GET,
      auth,
      undefined,
      tokenType,
    );
  }

  public async post<T>(
    endpoint: string,
    data?: any,
    auth: boolean = true,
    tokenType: TokenType = TokenType.ACCESS,
  ): Promise<T> {
    return this.request<T>(endpoint, HTTPMethods.POST, auth, data, tokenType);
  }

  public async patch<T>(
    endpoint: string,
    data: any,
    auth: boolean = true,
    tokenType: TokenType = TokenType.ACCESS,
  ): Promise<T> {
    return this.request<T>(endpoint, HTTPMethods.PATCH, auth, data, tokenType);
  }

  public async put<T>(
    endpoint: string,
    data: any,
    auth: boolean = true,
    tokenType: TokenType = TokenType.ACCESS,
  ): Promise<T> {
    return this.request<T>(endpoint, HTTPMethods.PUT, auth, data, tokenType);
  }

  public async delete<T>(
    endpoint: string,
    auth: boolean = true,
    tokenType: TokenType = TokenType.ACCESS,
  ): Promise<T> {
    return this.request<T>(
      endpoint,
      HTTPMethods.DELETE,
      auth,
      undefined,
      tokenType,
    );
  }

  private async request<T>(
    endpoint: string,
    method: HTTPMethods,
    auth: boolean = true,
    data?: any,
    tokenType: TokenType = TokenType.ACCESS,
    tries: number = 1,
  ): Promise<T | any> {
    const config: RequestConfig = {
      method,
      headers: {
        Origin: import.meta.env.VITE_ORIGIN,
      },
    };

    if (data) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
      config.body = JSON.stringify(data);
    }

    if (auth) await this.setAuthorization(config, tokenType);

    try {
      const response = await fetch(`${this.baseURL}${endpoint}`, config);
      const data = await response?.json?.();

      if (data?.error) {
        switch (data?.status) {
          case 498:
            if (tries >= 3) {
              removeCookies();
              return data;
            }

            return await this.request(
              endpoint,
              method,
              auth,
              data,
              tokenType,
              tries + 1,
            );
          default:
            return data;
        }
      }

      return data;
    } catch (error: any) {
      return { error: 'Internal Server error, try again.', status: 500 };
    }
  }
}
