// @ts-ignore
import UAuth from '@uauth/js';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from '../../context/error.context';
import { handleUnstoppable } from '../../handlers/wallets';
import { Wallet } from '../../types';

import { isWallet } from '../../utils/isType';

type Props = {
  setWallet(wallet: Wallet): void;
};

const UnstoppableConnect = ({ setWallet }: Props) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setError } = useErrorContext();

  const uauth = new UAuth({
    clientID: import.meta.env.VITE_UNSTOPPABLE_CLIENTID ?? '',
    redirectUri: import.meta.env.VITE_UNSTOPPABLE_URL ?? '',
    scope: 'openid wallet',
  });

  const handleClick = async () => {
    const wallet = await handleUnstoppable(uauth);
    if (typeof wallet === 'string')
      return setError(
        '',
        t(`errorHandling.wallets.${wallet}`, { ns: 'enumerations' }),
      );

    if (isWallet(wallet)) setWallet(wallet);
  };

  return (
    <button
      className='w-full rounded-12px bg-unstoppable px-4 py-2 text-white'
      onClick={handleClick}
    >
      <img
        src='images/unstoppable.jpg'
        className={'inline w-6'}
        alt='Unstoppable icon'
      />
      <span className='pl-3 md:pl-4'>
        {t('wallets.unstoppable', { ns: 'enumerations' })}
      </span>
    </button>
  );
};

export default UnstoppableConnect;
