import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../components/Button';
import Popup from '../components/PopupPage';
import WalletSelectionPopup from '../components/WalletSelectionPopup';
import { useUser } from '../context/user.context';
import { Wallet } from '../types';
import { getProjectURL } from '../utils';
import { PopUpFlow } from './WalletOrSocialAuthorization';

export const enum WalletFlow {
  connect = 'connect',
  select = 'select',
}

const WalletAuthorization: React.FC<{
  flowStep: WalletFlow;
  setWallet: (wallet: Wallet) => void;
}> = ({ flowStep, setWallet }) => {
  const { t } = useTranslation(['common']);
  const { setIsLoading } = useUser();
  // const [step, setStep] = useState<WalletFlow>(flowStep || WalletFlow.connect);
  const step = flowStep ?? WalletFlow.connect;
  const [popUpFlow, setPopUpFlow] = useState<null | PopUpFlow>();

  const handleWalletSelection = () => {
    setPopUpFlow(PopUpFlow.walletSelection);
  };

  const closeModal = () => {
    setPopUpFlow(null);
  };

  useEffect(() => setIsLoading(false), []);

  return (
    <>
      <Popup title={t('wallet_authorization', { ns: 'common' })}>
        <div className='px-10'>
          <div className='mt-4 mb-10 flex flex-col items-center'>
            {/* {businessPlaceholder.image && (
              <img
                className="mb-4 h-10"
                src={businessPlaceholder.image}
                alt={businessPlaceholder.name}
              />
            )} */}
            <h2 className='text-xl font-medium text-gray-dark mb-1'>
              {t('connect_your_wallet', { ns: 'common' })}
            </h2>
            <h3 className='mt-1 text-center'>
              {t('toContinue', { ns: 'common' })}
              <Link
                to={getProjectURL() ?? '/'}
                className='ml-2 font-medium text-gold'
              >
                {t(`projects.${origin}`, { ns: 'common' })}
              </Link>
            </h3>
          </div>
          {step === WalletFlow.connect && (
            <div className='m-auto w-56 mb-8'>
              <Button
                onClick={handleWalletSelection}
                size={ButtonSize.medium}
                type={ButtonType.secondary}
                justify={ButtonJustify.start}
                className='mb-2'
              >
                <div className='flex items-center'>
                  <img src='images/wallet.svg' />
                  <span className='pl-6'>
                    {t('buttons.connect_wallet', { ns: 'common' })}
                  </span>
                </div>
              </Button>
            </div>
          )}
          {/* {step === WalletFlow.select && (
            <>
              {wallets &&
                wallets.map((wallet) => (
                  <WalletLine key={wallet.id} wallet={wallet} />
                ))}
              <div
                onClick={handleWalletSelection}
                className="flex items-center py-1 border-b w-full cursor-pointer"
              >
                <div className="w-8 h-8 mr-3 flex justify-center items-center">
                  <img className="w-7 h-auto" src="images/wallet.svg" />
                </div>
                <span className="text-xsm">
                  {t("use_another_wallet", { ns: "common" })}
                </span>
              </div>
            </>
          )} */}
        </div>
        {getProjectURL() && (
          <div className='w-3/4 px-10 text-sm mt-6 text-gray-extralight90 text-center mx-auto'>
            <span>{t('once_you_authorize', { ns: 'common' })}</span>
            <Link to={getProjectURL()!} className='ml-2 text-gold underline'>
              {getProjectURL()}
            </Link>
          </div>
        )}
      </Popup>
      {popUpFlow === PopUpFlow.walletSelection && (
        <WalletSelectionPopup onCloseModal={closeModal} setWallet={setWallet} />
      )}
    </>
  );
};

export default WalletAuthorization;
