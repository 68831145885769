import { APIService } from './api.service';

export class UserService extends APIService {
  constructor() {
    super();
  }

  public async userExistsByWallet(walletAddress: string): Promise<any> {
    const response = await this.get<any>(
      `/fauna/user/check/${this.origin}/${walletAddress}`,
      false,
    );
    return response.user ?? response.error;
  }

  public async userExistsBySocial(
    social: string,
    socialId: string,
  ): Promise<any> {
    const response = await this.get<any>(
      `/fauna/user/check/${this.origin}/${social}/${socialId}`,
      false,
    );
    return response.user ?? response.error;
  }
}
