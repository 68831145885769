import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../components/Button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Social, Wallet } from '../types';
import { hermesOAuthPrivacyURL, socialURL } from '../utils/URLUtils';

import Popup from '../components/PopupPage';
import { getShorterWalletAddress } from '../utils';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user.context';

export const enum SocialOrWallet {
  wallet = 'wallet',
  user = 'user',
}

const Welcome: React.FC<{
  socialOrWallet: SocialOrWallet;
  wallet: Wallet | undefined;
  social: Social;
  setHasSignedTerms: Dispatch<SetStateAction<boolean>>;
}> = ({ wallet, social, setHasSignedTerms }) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setIsLoading } = useUser();
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

  const handleTermsCheck = () => setIsTermsChecked(!isTermsChecked);

  useEffect(() => setIsLoading(false), []);

  return (
    <Popup
      title={t('new_account_registration', { ns: 'common' })}
      marginBottom={false}
    >
      <div className='w-full px-10'>
        <div className='mb-8 mt-2 flex flex-col items-center'>
          <h2 className='mb-1 text-xl font-medium text-gray-dark'>
            {`${t('welcome', { ns: 'common' })} `}
            {wallet
              ? getShorterWalletAddress(wallet.walletAddress)
              : social.username}
            {`!`}
          </h2>
          <h3>{t('first_time', { ns: 'common' })}</h3>
        </div>
        <p className='mb-8 text-sm text-gray-dark'>
          {t('privacy_and_terms_msg', { ns: 'common' })}
        </p>

        <div className='text-gray-darksmsolid mb-6 text-xs'>
          <label className='flex'>
            <input
              type='checkbox'
              checked={isTermsChecked}
              className='form-checkbox rounded-sm text-gold
                                focus:ring
                                focus:ring-transparent'
              onChange={handleTermsCheck}
            />
            <span className='text-sxm ml-2'>
              {`${t('checkbox_terms_acceptance', { ns: 'common' })} `}
              <a
                href={hermesOAuthPrivacyURL}
                target='_blank'
                className={'text-muddywaters-500 underline hover:text-gray-800'}
              >
                {t('helpers.terms_use', { ns: 'common' })}
              </a>
              {` ${t('actions.and', { ns: 'common' })} ${t('helpers.our', {
                ns: 'common',
              })} `}
              <a
                href={hermesOAuthPrivacyURL}
                target='_blank'
                className={'text-muddywaters-500 underline hover:text-gray-800'}
              >
                {t('helpers.privacy_policy', { ns: 'common' }).toLowerCase()}
              </a>
              .
            </span>
          </label>
        </div>
      </div>
      <div className='mb-2 mt-5 flex w-full justify-end px-10'>
        <div className='w-28'>
          <Button
            disabled={isTermsChecked ? false : true}
            onClick={
              isTermsChecked
                ? () => setHasSignedTerms(true)
                : () => setHasSignedTerms(false)
            }
            size={ButtonSize.small}
            type={ButtonType.primary}
            justify={ButtonJustify.center}
            className='mb-2'
          >
            <span className='text-sm'>
              {t('actions.continue', { ns: 'common' })}
            </span>
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default Welcome;
