import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader, { LoaderType } from '../components/Loader';
import { useErrorContext } from '../context/error.context';
import { useUser } from '../context/user.context';
import { handleDiscord, handleTelegram } from '../handlers/socials';
import { useStickyState } from '../hooks/useStickyState';
import { Social } from '../types';

export const HandleSocials: React.FC = () => {
  const { t } = useTranslation(['enumerations']);

  const { isLoading, setIsLoading } = useUser();
  const { setError, clearError } = useErrorContext();
  const [socialObj, setSocialObj] = useStickyState<Social | {}>({}, 'social');

  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const social = params.social;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      switch (social) {
        case 'discord': {
          if (searchParams.get('error')) return navigate('/');

          const state = searchParams.get('state') ?? '';
          const code = searchParams.get('code') ?? '';

          const data = await handleDiscord(state, code);

          if (!data) {
            setError('', t('errorHandling.getUser', { ns: 'enumerations' }));
            setTimeout(() => {
              clearError();
              navigate('/');
            }, 1000);
            return;
          }

          setSocialObj(data);
          return;
        }
        case 'google':
          setSocialObj({
            social: 'google',
            email: searchParams.get('email') ?? '',
            socialId: searchParams.get('id') ?? '',
            username: searchParams.get('name') ?? '',
          });
          return;
        case 'telegram': {
          const data = handleTelegram();

          if (!data) {
            setError(
              '',
              t('errorHandling.validateTelegram', { ns: 'enumerations' }),
            );
            setTimeout(() => {
              clearError();
              navigate('/');
            }, 1000);
            return;
          }

          setSocialObj(data);
          return;
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(socialObj).length !== 0) {
      setIsLoading(false);
      navigate('/');
    }
  }, [socialObj]);

  if (isLoading) return <Loader type={LoaderType.fullScreen} />;
  return <></>;
};
