import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonJustify, ButtonSize, ButtonType } from './Button';
import Popup, { PopupSize } from './Popup';

const RegistrationPopup: React.FC<{
  onCloseModal: React.MouseEventHandler;
  onWalletClick: React.MouseEventHandler;
  setUserChoice: Dispatch<SetStateAction<boolean | undefined>>;
}> = ({ onCloseModal, onWalletClick }) => {
  const { t } = useTranslation(['common']);
  return (
    <Popup size={PopupSize.medium} closeModal={onCloseModal}>
      <div className='px-5'>
        <div className='text-lg mb-6'>
          {t('continue_with', { ns: 'common' })}
        </div>
        <div className='m-auto w-48'>
          <Button
            onClick={onWalletClick}
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            justify={ButtonJustify.start}
            className='mb-2'
          >
            <div className='flex items-center'>
              <img src='images/wallet.svg' />
              <span className='pl-9'>
                {t('buttons.wallet', { ns: 'common' })}
              </span>
            </div>
          </Button>
          {/* <div className='text-center border-b text-gold border-gold leading-0.5 mt-3 mb-5'>
            <span className='px-2 bg-white'>
              {t('actions.or', { ns: 'common' })}
            </span>
          </div>
          <Button
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            justify={ButtonJustify.start}
            className='mb-2'
            onClick={() => setUserChoice(true)}
          >
            <div className='flex items-center'>
              <FontAwesomeIcon
                icon={faUser}
                className='text-gray-darksmsolid text-2xl'
              />
              <span className='pl-9'>
                {t('buttons.social', { ns: 'common' })}
              </span>
            </div>
          </Button> */}
        </div>
      </div>
    </Popup>
  );
};

export default RegistrationPopup;
