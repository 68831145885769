import { api } from '../shared';
import { Social } from '../types';

/* @ts-ignore */
import { createHash, createHmac } from 'node:crypto';

const searchParams = new URLSearchParams(window.location.search);
const telegramBotToken = import.meta.env.VITE_BOT_TOKEN;

export const handleDiscord = async (
  state: string,
  code: string,
): Promise<Social | false> => {
  const stateCheck = JSON.parse(sessionStorage.getItem('state_check') || '{}');

  if (state !== stateCheck) return false;

  const res = await api.social.getSocial(
    {
      code,
      codeVerifier: '',
    },
    'discord',
  );

  if (res.error) return false;

  return {
    social: 'discord',
    socialId: res.user.id,
    username: res.user.username,
    token: res.oAuth.access_token,
    expiration: res.oAuth.expires_in,
    refreshToken: res.oAuth.refresh_token,
  };
};

export const handleTelegram = (): Social | false => {
  let telegramData: any = {};

  searchParams.forEach((value, key) => {
    telegramData = {
      ...telegramData,
      [key]: value,
    };
  });

  if (!telegramBotToken) return false;

  const secret = createHash('sha256').update(telegramBotToken).digest();

  function checkSignature({ hash, ...data }: any) {
    const checkString = Object.keys(data)
      .sort()
      .filter((k) => data[k])
      .map((k) => `${k}=${data[k]}`)
      .join('\n');
    const hmac = createHmac('sha256', secret).update(checkString).digest('hex');
    return hmac === hash;
  }

  if (!checkSignature(telegramData)) return false;

  return {
    social: 'telegram',
    socialId: telegramData.id,
    username: telegramData.username ?? telegramData.first_name,
    hash: telegramData.hash,
    url: telegramData.photo_url,
  };
};
