import { TokenType } from '../types/enums';
import { removeCookies, setCookies } from '../utils';
import { APIService } from './api.service';

export class AuthService extends APIService {
  constructor() {
    super();
  }

  public async isLoggedIn(): Promise<any> {
    return await this.get(`/auth/isLoggedIn`);
  }

  public async login(data: any): Promise<any> {
    const response = await this.post<any>(
      `/auth/login/${this.origin}`,
      data,
      false,
    );

    if (response.status === 200) {
      setCookies('refreshToken', response.refreshToken);
      setCookies('accessToken', response.accessToken);
    }

    return response;
  }

  public async signup(data: any): Promise<any> {
    const response = await this.post<any>(
      `/auth/signup/${this.origin}`,
      data,
      false,
    );

    return response;
  }

  public async signupSocial(social: string, data: any): Promise<any> {
    const response = await this.post<any>(
      `/auth/signup/${social}/${this.origin}`,
      data,
      false,
    );

    return response;
  }

  public async logout(): Promise<boolean> {
    const response = await this.post<any>(
      `/auth/logout`,
      undefined,
      true,
      TokenType.REFRESH,
    );
    if (response.status === 200) removeCookies();

    return response.status === 200 ? true : false;
  }

  public async bytes(data: any): Promise<any> {
    const { bytes } = await this.post<any>(`/auth/bytes`, data, false);
    return bytes;
  }

  public async bytesRadix(): Promise<any> {
    const { bytes } = await this.get<any>(`/auth/bytes-radix`, false);
    return bytes;
  }
}
