import { APIService } from './api.service';

export class WalletService extends APIService {
  constructor() {
    super();
  }

  public async addWallet(data: any): Promise<any> {
    return await this.post(`/wallet/${this.origin}`, data, true);
  }
}
