import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDiscord,
  faTelegramPlane,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonSize } from '../components/Button';
import Popup from '../components/PopupPage';
import { useUser } from '../context/user.context';
import { BlockchainImage } from '../types/enums';
import { getCookies, getShorterWalletAddress, redirect } from '../utils';

interface LoginInfo {
  name: string;
  image: React.ReactNode;
}
const WelcomeBack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'enumerations']);
  const { userData, logout, setIsLoading } = useUser();
  const [loginInfo, setLoginInfo] = useState<LoginInfo | undefined>(undefined);
  const defaultLoginImage: React.ReactNode = (
    <FontAwesomeIcon icon={faUser} className='text-md mr-3' />
  );

  const getSocialImage = (socialType: string) => {
    switch (socialType) {
      case 'discord':
        return (
          <FontAwesomeIcon
            icon={faDiscord as IconProp}
            className='text-md mr-3 text-discord'
          />
        );
      case 'telegram':
        return (
          <FontAwesomeIcon
            icon={faTelegramPlane as IconProp}
            className='text-lg mr-3 text-telegram'
          />
        );
      case 'twitter':
        return (
          <FontAwesomeIcon
            icon={faTwitter as IconProp}
            className='text-md mr-3 text-twitter'
          />
        );
      default:
        return defaultLoginImage;
    }
  };

  const getBlockchainImage = (chainId: keyof typeof BlockchainImage) => {
    const blockchainImage = BlockchainImage[chainId];
    if (!blockchainImage) {
      return defaultLoginImage;
    }
    return (
      <div
        className='bg-contain w-6 h-6 mr-3'
        style={{ backgroundImage: `url('${blockchainImage}')` }}
      ></div>
    );
  };

  const getLoginInfo = () => {
    //if has alias returns alias
    if (userData?.clientAlias) {
      const loginInfoData: LoginInfo = {
        name: userData?.clientAlias,
        image: defaultLoginImage,
      };
      setLoginInfo(loginInfoData);
    }
    //if has a social attached return first social name found
    else if (userData?.socials && userData?.socials.length > 0) {
      const socialData = userData?.socials.find(
        (social: any) => social.username,
      );
      if (socialData) {
        const loginInfoData: LoginInfo = {
          name: socialData.username,
          image: socialData?.type
            ? getSocialImage(socialData.type)
            : defaultLoginImage,
        };
        setLoginInfo(loginInfoData);
      }
    }
    //else return first wallet address found
    else {
      const walletData =
        userData?.wallets &&
        userData?.wallets.find((wallet: any) => wallet.walletAddress);
      if (walletData) {
        const loginInfoData: LoginInfo = {
          name: getShorterWalletAddress(walletData.walletAddress),
          image: getBlockchainImage(
            walletData.chainId as keyof typeof BlockchainImage,
          ),
        };
        setLoginInfo(loginInfoData);
      }
    }
  };
  const handleNewLogin = () => {
    logout();
    navigate('/');
  };
  useEffect(() => {
    setIsLoading(false);
    getLoginInfo();
  }, []);

  return (
    <Popup>
      <div className='px-10'>
        <div className='mt-10 mb-8 flex flex-col items-center'>
          <h2 className='text-xl font-medium text-gray-dark mb-1 mt-6'>
            {t('welcome_back', { ns: 'common' })}
          </h2>
          <h4 className='text-md text-gray-dark mb-1'>
            {t('login_this_account', { ns: 'common' })}
          </h4>
          <div className='mt-8 m-auto w-60 min-h-10'>
            {loginInfo && (
              <div className='flex items-center px-4 py-2 border rounded-8px w-full text-gray-light50'>
                {loginInfo?.image}
                <span className='mt-0.5 text-xsm text-gray-darksmsolid'>
                  {loginInfo?.name}
                </span>
              </div>
            )}
          </div>
          <div className='mt-6 mb-4 w-32'>
            <Button
              size={ButtonSize.small}
              onClick={() => {
                setIsLoading(true);
                redirect(getCookies().refreshToken);
              }}
            >
              {t('actions.continue', { ns: 'common' })}
            </Button>
          </div>
        </div>
        <div className='mb-8 text-sm text-gray-medium text-center'>
          {t('connect_another_account', { ns: 'common' })}
          <span
            className='ml-1 text-gold underline cursor-pointer'
            onClick={() => handleNewLogin()}
          >
            {t('login', { ns: 'common' })}
          </span>
        </div>
      </div>
    </Popup>
  );
};

export default WelcomeBack;
