import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Loader, { LoaderType } from '../components/Loader';
import { useErrorContext } from '../context/error.context';
import { useUser } from '../context/user.context';
import { useStickyState } from '../hooks/useStickyState';
import { api } from '../shared';
import { Social, Wallet } from '../types';
import { redirect, resetLocalStorage } from '../utils';
import { isSocial, isWallet } from '../utils/isType';
import SocialAuthorization from './SocialAuthorization';
import WalletAuthorization, { WalletFlow } from './WalletAuthorization';
import WalletOrSocialAuthorization from './WalletOrSocialAuthorization';
import Welcome, { SocialOrWallet } from './Welcome';
import WelcomeBack from './WelcomeBack';

const Homepage: React.FC = () => {
  //* Translations
  const { t } = useTranslation(['enumeration']);
  //* User
  const { setUser, isLoggedIn, login, isLoading, setIsLoading } = useUser();
  //* URL Params
  const [searchParams] = useSearchParams();

  const origin =
    searchParams.get('origin') ?? localStorage.getItem('origin') ?? 'webapp';
  // const contactListId = searchParams.get('contactListId') ?? '';
  // const isWalletMandatory = searchParams.get('walletMandatory') ?? false;
  // const blockchains = searchParams.get('blockchains')?.split(',') ?? [];
  const action =
    searchParams.get('action') ?? localStorage.getItem('action') ?? '';
  const sentUserId = searchParams.get('userId') ?? '';

  //* Local Storage
  localStorage.setItem('origin', origin);
  localStorage.setItem('action', action || '');
  localStorage.setItem('sentUserId', sentUserId || '');

  //* Flow
  const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
  const [social, setSocial] = useStickyState<Social | {}>({}, 'social');
  const [hasSignedTerms, setHasSignedTerms] = useState<boolean>(false);
  const [userChoice, setUserChoice] = useState<boolean | undefined>(undefined);

  //* Add error context
  const { setError } = useErrorContext();

  const resetFlow = () => {
    setSocial({});
    setWallet(undefined);
    setUserChoice(undefined);
  };

  const handleSetUser = async (connectedWallet: Wallet) => {
    //Check if user exists
    const userExists = await api.user
      .userExistsByWallet(connectedWallet.walletAddress)
      .then((response: any) => {
        if (response?.error) {
          setError(
            response?.error,
            t('errorHandling.getUser', { ns: 'enumerations' }),
          );
          resetFlow();
          return;
        }
        setUser(response);
        return response;
      });

    switch (action) {
      case 'addWallet':
        if (!isLoggedIn) {
          login({
            walletAddress: connectedWallet.walletAddress,
            chainId: connectedWallet.blockchain,
            signBytesResult: connectedWallet.signedBytes,
            originalBytes: connectedWallet.originalBytes,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
            }
            setIsLoading(false);
          });

          return;
        }
        //Add wallet to user
        api.wallet
          .addWallet({
            walletAddress: connectedWallet.walletAddress,
            chainId: connectedWallet.blockchain,
            signBytesResult: connectedWallet.signedBytes,
            originalBytes: connectedWallet.originalBytes,
          })
          .then((response: any) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.addWallet', { ns: 'enumerations' }),
              );
              resetFlow();
              setIsLoading(false);
              return;
            }
            resetLocalStorage();
            redirect(response.refreshToken);
          });
        break;
      case 'addSocial':
        if (!isLoggedIn) {
          login({
            walletAddress: connectedWallet.walletAddress,
            chainId: connectedWallet.blockchain,
            signBytesResult: connectedWallet.signedBytes,
            originalBytes: connectedWallet.originalBytes,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
            }
            setIsLoading(false);
          });
        }

        break;
      default:
        if (!userExists) {
          setIsLoading(false);
          break;
        }
        //Login the already existing user
        login({
          walletAddress: connectedWallet.walletAddress,
          chainId: connectedWallet.blockchain,
          signBytesResult: connectedWallet.signedBytes,
          originalBytes: connectedWallet.originalBytes,
        }).then((response) => {
          if (response?.error) {
            setError(
              response?.error,
              t('errorHandling.noLogin', { ns: 'enumerations' }),
            );
            resetFlow();
            setIsLoading(false);
            return;
          }
          resetLocalStorage();
          redirect(response.refreshToken);
        });
        break;
    }
  };

  const handleSetWallet = async (connectedWallet: Wallet) => {
    setIsLoading(true);
    setWallet(connectedWallet);
    handleSetUser(connectedWallet);
  };

  const handleSetUserSocial = async (connectedSocial: Social) => {
    const userExists = await api.user
      .userExistsBySocial(connectedSocial.social, connectedSocial.socialId)
      .then((response: any) => {
        if (response?.error) {
          setError(
            response?.error,
            t('errorHandling.getUser', { ns: 'enumerations' }),
          );
          resetFlow();
          return;
        }
        setUser(response);
        return response;
      });

    switch (action) {
      case 'addSocial':
        if (!isLoggedIn) {
          login({
            social: connectedSocial.social,
            socialId: connectedSocial.socialId,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
            }
            setIsLoading(false);
          });

          return;
        }
        //Add social to user
        api.social
          .addSocial(
            {
              ...connectedSocial,
              social: undefined,
            },
            connectedSocial.social,
          )
          .then((response: any) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.addWallet', { ns: 'enumerations' }),
              );
              resetFlow();
              setIsLoading(false);
              return;
            }
            resetLocalStorage();
            redirect(response.refreshToken);
          });
        break;
      case 'addWallet':
        if (!isLoggedIn) {
          login({
            social: connectedSocial.social,
            socialId: connectedSocial.socialId,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
            }
            setIsLoading(false);
          });
        }

        break;
      default:
        if (!userExists) {
          setIsLoading(false);
          if (origin !== 'business') {
            setError(
              `User doesn't exist`,
              t('errorHandling.noLogin', { ns: 'enumerations' }),
            );
          }
          break;
        }
        //Login the already existing user
        login({
          social: connectedSocial.social,
          socialId: connectedSocial.socialId,
        }).then((response) => {
          if (response?.error) {
            setError(
              response?.error,
              t('errorHandling.noLogin', { ns: 'enumerations' }),
            );
            resetFlow();
            setIsLoading(false);
            return;
          }
          resetLocalStorage();
          redirect(response.refreshToken);
        });
        break;
    }
  };

  useEffect(() => {
    if (Object.entries(social).length === 0) return;

    setIsLoading(true);
    handleSetUserSocial(social as Social);
  }, [social]);

  const renderUI = () => {
    if (isLoading) return <Loader type={LoaderType.fullScreen} />;

    if (isLoggedIn && !action) return <WelcomeBack />;
    else if (userChoice) return <SocialAuthorization />;
    else if (action) {
      if (isLoggedIn)
        switch (action) {
          case 'addSocial':
            return <SocialAuthorization />;
          case 'addWallet':
            return (
              <WalletAuthorization
                flowStep={WalletFlow.connect}
                setWallet={handleSetWallet}
              />
            );
          default:
            return <WelcomeBack />;
        }
      else
        return (
          <WalletOrSocialAuthorization
            setWallet={handleSetWallet}
            setUserChoice={setUserChoice}
          />
        );
    } else if (
      (wallet || (social && isSocial(social) && origin === 'business')) &&
      !isLoggedIn &&
      !hasSignedTerms
    ) {
      return (
        <Welcome
          socialOrWallet={SocialOrWallet.wallet}
          wallet={wallet}
          social={social as Social}
          setHasSignedTerms={setHasSignedTerms}
        />
      );
    } else
      return (
        <WalletOrSocialAuthorization
          setWallet={handleSetWallet}
          setUserChoice={setUserChoice}
        />
      );
  };

  useEffect(() => {
    if (hasSignedTerms) {
      setIsLoading(true);
      if (wallet && isWallet(wallet)) {
        (async () => {
          await api.auth
            .signup({
              walletAddress: wallet.walletAddress,
              chainId: wallet.blockchain,
              signBytesResult: wallet.signedBytes,
              originalBytes: wallet.originalBytes,
            })
            .then((response: any) => {
              if (response?.error) {
                setError(
                  response?.error,
                  t('errorHandling.noSignup', { ns: 'enumerations' }),
                );
                resetFlow();
                setIsLoading(false);
              }
            });

          await login({
            walletAddress: wallet.walletAddress,
            chainId: wallet.blockchain,
            signBytesResult: wallet.signedBytes,
            originalBytes: wallet.originalBytes,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
              setIsLoading(false);
              return;
            }
            resetLocalStorage();
            redirect(response.refreshToken);
          });
        })();
      }
      if (social && isSocial(social))
        (async () => {
          await api.auth
            .signupSocial(social.social, {
              ...social,
              social: undefined,
            })
            .then((response: any) => {
              if (response?.error) {
                setError(
                  response?.error,
                  t('errorHandling.noSignup', { ns: 'enumerations' }),
                );
                resetFlow();
                setIsLoading(false);
              }
            });
          await login({
            social: social.social,
            socialId: social.socialId,
          }).then((response) => {
            if (response?.error) {
              setError(
                response?.error,
                t('errorHandling.noLogin', { ns: 'enumerations' }),
              );
              resetFlow();
              setIsLoading(false);
              return;
            }
            resetLocalStorage();
            redirect(response.refreshToken);
          });
        })();
    }
  }, [hasSignedTerms]);

  return <div className='bg-gray-extralight10'>{renderUI()}</div>;
};

export default Homepage;
