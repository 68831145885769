import { api } from '../shared';
import { BlockchainType, Wallet } from '../types';

declare global {
  interface Window {
    keplr: any;
    ethereum: any;
    xfi: {
      ethereum: any;
    };
    near: any;
    station: any;
  }
}

export type ErrorMessage =
  keyof typeof import('../../public/locales/en/enumerations.json').errorHandling.wallets;

export const handleConnectEthereum = async (
  wallet?: string,
): Promise<Wallet | ErrorMessage> => {
  const ethereum = wallet === 'xdefi' ? window.xfi.ethereum : window.ethereum;

  let accounts;
  try {
    accounts = await ethereum.request({
      method: 'eth_requestAccounts',
    });
    if (!accounts.length) throw null;
  } catch (e) {
    return 'noConnection';
  }

  let originalBytes;
  try {
    originalBytes = await api.auth.bytes({
      walletAddress: accounts[0],
    });
    if (!originalBytes) throw null;
  } catch (e) {
    return 'noBytes';
  }

  let signature;
  try {
    signature = await ethereum.request({
      method: 'personal_sign',
      params: [originalBytes, accounts[0]],
    });
    if (!signature) throw null;
  } catch (e) {
    return 'noSignature';
  }

  return {
    walletAddress: accounts[0],
    blockchain: BlockchainType.EVM,
    originalBytes,
    signedBytes: signature,
  };
};

export const handleUnstoppable = async (
  uauth: any,
): Promise<Wallet | ErrorMessage> => {
  let data;
  try {
    data = await uauth.loginWithPopup();
  } catch (e) {
    return 'unstoppable';
  }

  return {
    walletAddress: data.idToken.wallet_address,
    blockchain: BlockchainType.EVM,
    originalBytes: data.idToken.eip4361_message,
    signedBytes: data.idToken.eip4361_signature,
  };
};

export const handleConnectSender = async (): Promise<Wallet | ErrorMessage> => {
  const near = window.near;

  let address;
  try {
    address = await near.requestAccountId();
    if (!address) throw null;
  } catch (e) {
    return 'noConnection';
  }

  let originalBytes;
  try {
    originalBytes = await api.auth.bytes({
      walletAddress: address,
    });
    if (!originalBytes) throw null;
  } catch (e) {
    return 'noBytes';
  }

  // @ts-ignore
  const nonce = Buffer.from(originalBytes.slice(0, 32));

  let signature;
  try {
    signature = await near.signMessage({
      message: originalBytes,
      recipient: address,
      nonce,
    });
    if (!signature) throw null;
  } catch (e) {
    return 'noSignature';
  }

  return {
    walletAddress: address,
    blockchain: BlockchainType.Near,
    originalBytes,
    signedBytes: signature.response,
  };
};

export const handleConnectStation = async (): Promise<
  Wallet | ErrorMessage
> => {
  const station = window.station;

  let connection;
  try {
    connection = await station.connect();
    if (!connection) throw null;
  } catch (e) {
    return 'noConnection';
  }

  if (connection.ledger) return 'noLedgerSupport';

  let originalBytes;
  try {
    originalBytes = await api.auth.bytes({
      walletAddress: connection.address,
    });
    if (!originalBytes) throw null;
  } catch (e) {
    return 'noBytes';
  }

  let signature;
  try {
    signature = await station.signBytes(btoa(originalBytes));
    if (!signature) throw null;
  } catch (e) {
    return 'noSignature';
  }

  return {
    walletAddress: connection.address,
    blockchain: BlockchainType.Terrav2,
    originalBytes,
    signedBytes: signature,
  };
};
