import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useErrorContext } from '../context/error.context';
import Button, { ButtonJustify, ButtonSize, ButtonType } from './Button';
import PopUp, { PopupSize } from './Popup';

const ErrorHandling = () => {
  const { config, clearError } = useErrorContext();
  const { t } = useTranslation(['common']);
  return (
    <>
      {config && (
        <>
          <div
            className='z-50 fixed top-0 left-0 right-0 bottom-0 bg-popupBackdrop cursor-pointer'
            onClick={() => clearError()}
          ></div>
          <PopUp size={PopupSize.medium} closeModal={() => clearError()}>
            <div className='text-center px-4'>
              <div className='flex grow-0 justify-center text-white'>
                <span
                  className={`w-10 h-10 mb-4 leading-9 p-1 text-xl rounded-full text-center bg-red`}
                >
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </span>
              </div>
              <h3 className='mb-3 text-lg font-medium'>{config.title}</h3>
              <p className='text-lg mb-6'>{config.text}</p>
              <div className='flex justify-center'>
                <Button
                  className='mb-2'
                  size={ButtonSize.small}
                  justify={ButtonJustify.center}
                  type={ButtonType.secondary}
                  onClick={clearError}
                >
                  {t('actions.close', { ns: 'common' })}
                </Button>
              </div>
            </div>
          </PopUp>
        </>
      )}
    </>
  );
};
export default ErrorHandling;
