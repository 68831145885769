import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from '../components/Button';
import PopupPage from '../components/PopupPage';
import RegistrationPopup from '../components/RegistrationPopup';
import WalletSelectionPopup from '../components/WalletSelectionPopup';
import { useUser } from '../context/user.context';
import { origin } from '../shared';
import { Wallet } from '../types';
import { getProjectURL } from '../utils';

export const enum PopUpFlow {
  start = 'start',
  walletSelection = 'walletSelection',
}
const WalletOrSocialAuthorization: React.FC<{
  setWallet: (wallet: Wallet) => void;
  setUserChoice: Dispatch<SetStateAction<boolean | undefined>>;
}> = ({ setWallet, setUserChoice }) => {
  const { t } = useTranslation(['common']);
  const { setIsLoading } = useUser();

  const [popUpFlow, setPopUpFlow] = useState<null | PopUpFlow>();
  const [goBackActive, setGoBackActive] = useState<boolean>(false);

  const handleWalletSelection = (activateGoBackButton: boolean) => {
    setPopUpFlow(PopUpFlow.walletSelection);
    activateGoBackButton ? setGoBackActive(true) : setGoBackActive(false);
  };

  const closeModal = () => {
    setPopUpFlow(null);
    setGoBackActive(false);
  };

  const handleRegister = () => {
    setPopUpFlow(PopUpFlow.start);
  };

  // const handleGoBack = () => {
  //   setPopUpFlow(PopUpFlow.start);
  // };

  useEffect(() => setIsLoading(false), []);

  return (
    <>
      <PopupPage title={t('wallet_social_authorization', { ns: 'common' })}>
        <div className='px-10'>
          <div className='mt-4 mb-10 flex flex-col items-center'>
            {/* {businessPlaceholder.image && (
              <img
                className='mb-4 h-10'
                src={businessPlaceholder.image}
                alt={businessPlaceholder.name}
              />
            )} */}
            <h2 className='text-xl font-medium text-gray-dark mb-1 text-center'>
              {t('login_with_wallet_social', { ns: 'common' })}
            </h2>
            <h3 className='mt-1 text-center'>
              {t('toContinue', { ns: 'common' })}
              <Link
                to={getProjectURL() ?? '/'}
                className='ml-2 font-medium text-gold'
              >
                {t(`projects.${origin}`, { ns: 'common' })}
              </Link>
            </h3>
          </div>
          <div className='m-auto w-56'>
            <Button
              onClick={() => handleWalletSelection(false)}
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              justify={ButtonJustify.start}
              className='mb-2'
            >
              <div className='flex items-center'>
                <img src='images/wallet.svg' />
                <span className='pl-6'>
                  {t('buttons.connect_wallet', { ns: 'common' })}
                </span>
              </div>
            </Button>
            <div className='text-center border-b text-gold border-gold leading-0.5 mt-3 mb-5'>
              <span className='px-2 bg-white'>
                {t('actions.or', { ns: 'common' })}
              </span>
            </div>
            <Button
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              justify={ButtonJustify.start}
              className='mb-2'
              onClick={() => setUserChoice(true)}
            >
              <div className='flex items-center'>
                <FontAwesomeIcon
                  icon={faUser}
                  className='text-gray-medium80 text-2xl'
                />
                <span className='pl-6'>
                  {t('buttons.social_account', { ns: 'common' })}
                </span>
              </div>
            </Button>
          </div>
          <div className='my-6 text-gray-darksmsolid text-center'>
            {`${t('to_registration_text', { ns: 'common' })} `}
            <span
              className='text-gold underline cursor-pointer'
              onClick={handleRegister}
            >
              {t('actions.here', { ns: 'common' })}
            </span>
          </div>
        </div>
        {getProjectURL() && (
          <div className='px-10 text-sm mt-6 text-gray-extralight90 text-center'>
            <span>{t('once_you_authorize', { ns: 'common' })}</span>
            <Link to={getProjectURL()!} className='ml-2 text-gold underline'>
              {getProjectURL()}
            </Link>
          </div>
        )}
      </PopupPage>
      {popUpFlow === PopUpFlow.start && (
        <RegistrationPopup
          onCloseModal={closeModal}
          onWalletClick={() => handleWalletSelection(true)}
          setUserChoice={setUserChoice}
        />
      )}
      {popUpFlow === PopUpFlow.walletSelection && !goBackActive && (
        <WalletSelectionPopup onCloseModal={closeModal} setWallet={setWallet} />
      )}
      {popUpFlow === PopUpFlow.walletSelection && goBackActive && (
        <WalletSelectionPopup onCloseModal={closeModal} setWallet={setWallet} />
      )}
    </>
  );
};

export default WalletOrSocialAuthorization;
