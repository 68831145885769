import React, { createContext, useCallback, useContext, useState } from 'react';

/* Interface creation */
export interface ErrorContextType {
  config: ErrorConfig | null;
  setError(title: string, text: string): void;
  clearError(): void;
}

export interface ErrorConfig {
  title: string;
  text: string;
}
/* Context */
const ErrorContext = createContext<ErrorContextType>({
  config: null,
  setError: () => {},
  clearError: () => {},
});

/* Using Context*/
export const useErrorContext = () => useContext(ErrorContext);

/* Provider fn */
export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [configState, setConfigState] = useState<ErrorConfig | null>(null);
  const clearError = () => {
    setConfigState(null);
  };
  const setError = (title: string, text: string) => {
    setConfigState({ title, text });
  };

  const contextValue = {
    config: configState,
    setError: useCallback(
      (title: string, text: string) => setError(title, text),
      [],
    ),
    clearError: useCallback(() => clearError(), []),
  };

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
};
