// @ts-ignore
// import-sort-ignore

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Wallet } from '../types';
import Button, { ButtonJustify, ButtonSize, ButtonType } from './Button';
import Popup, { PopupSize } from './Popup';

import wallets from '../assets/wallets.json';
import { useErrorContext } from '../context/error.context';
import {
  ErrorMessage,
  handleConnectEthereum,
  handleConnectSender,
  handleConnectStation,
} from '../handlers/wallets';
import { isWallet } from '../utils/isType';
import EachConnect from './connectButtons/EachConnect';
import RadixConnect from './connectButtons/RadixConnect';
import UnstoppableConnect from './connectButtons/UnstoppableConnect';

const WalletSelectionPopup: React.FC<{
  onCloseModal: React.MouseEventHandler;
  onGoBack?: React.MouseEventHandler;
  setWallet: (wallet: Wallet) => void;
}> = ({ onCloseModal, onGoBack, setWallet }) => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setError } = useErrorContext();

  // const [isOpenModal, setIsOpenModal] = useState(false);
  // const [modalContent, setModalContent] = useState<null | ModalState>(null);
  // const [currentStep, setCurrentStep] = useState(
  //   /* status === WalletStatus.WALLET_CONNECTED || isConnectedZ3us
  //     ? LoginStep.verifyWallet
  //     : */ LoginStep.connectWallet,
  // );

  const inCodeCondition: Record<string, boolean> = {};

  const handlers: Record<string, () => Promise<Wallet | ErrorMessage>> = {
    XDEFI: () => handleConnectEthereum('xdefi'),
    MetaMask: handleConnectEthereum,
    Sender: handleConnectSender,
    Station: handleConnectStation,
  };

  const handleConnectWallet = async (wallet: string) => {
    const connectWallet = await handlers[wallet]();
    if (typeof connectWallet === 'string')
      return setError(
        '',
        t(`errorHandling.wallets.${connectWallet}`, { ns: 'enumerations' }),
      );

    if (isWallet(connectWallet)) setWallet(connectWallet);
  };

  return (
    <Popup size={PopupSize.medium} closeModal={onCloseModal}>
      <div className={classNames({ 'mb-6': !onGoBack })}>
        <div className='text-l mb-4 font-medium text-gray-dark'>
          {t('choose_wallet', { ns: 'common' })}
        </div>
        <div className='m-auto w-48'>
          {/* Radix Connect */}
          <RadixConnect setWallet={setWallet} />

          {/* Dynamic JSON Wallets */}
          {wallets.map((w) => {
            if (!w.enabled) return;
            let condition: boolean = false;

            if (w.inWindowCondition)
              condition = !!(window as any)[w.inWindowCondition];

            if (w.inCodeCondition) condition = inCodeCondition[w.name];

            return condition ? (
              <EachConnect
                wallet={w}
                onClick={() => handleConnectWallet(w.name)}
                key={w.name}
              >
                {w.name} {w.extraName}
              </EachConnect>
            ) : (
              <a
                href={w.installExtension}
                target='_blank'
                rel='noreferrer'
                key={w.name}
              >
                <EachConnect wallet={w}>Install {w.name}</EachConnect>
              </a>
            );
          })}

          {/* Unstoppable Button */}
          <UnstoppableConnect setWallet={setWallet} />
        </div>
        {onGoBack && (
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.square}
            justify={ButtonJustify.start}
            className='mt-6'
            onClick={onGoBack}
          >
            <div>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className='text-gray-dark'
              />
            </div>
          </Button>
        )}
        {/* {isOpenModal && (
          <ConnectWallet
            setIsOpenModal={setIsOpenModal}
            setModalContent={setModalContent}
            modalContent={modalContent}
            authFlow={AuthFlow.login}
            setCurrentStep={setCurrentStep}
            setZ3usWallet={setZ3usWallet}
          />
        )} */}
      </div>
    </Popup>
  );
};

export default WalletSelectionPopup;
