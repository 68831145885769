import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';

export enum PopupSize {
  small = 'small',
  medium = 'medium',
}

const Popup: React.FC<{
  children: React.ReactNode;
  closeModal?: Dispatch<SetStateAction<any>>;
  size?: PopupSize;
}> = ({ children, size = PopupSize.medium, closeModal }) => {
  return (
    <>
      <div className='z-50 w-full h-full flex items-center fixed inset-0'>
        <div
          className='fixed inset-0 bg-black-transparentExtraLight h-full w-full z-50'
          onClick={closeModal}
        ></div>
        <div
          className={classNames(
            'popup-inside bg-white mx-auto h-auto max-h-full rounded-xl shadow-lg py-3 flex flex-col justify-evenly items-center z-50',
            {
              'max-w-lg px-6 py-6': size === PopupSize.medium,
              'max-w-sm': size === PopupSize.small,
            },
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Popup;
