import './i18n';
import './index.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorHandling from './components/ErrorHandling';
import Loader, { LoaderType } from './components/Loader';
import { ErrorProvider } from './context/error.context';
import { UserContextProvider, useUser } from './context/user.context';

import { HandleSocials } from './views/HandleSocials';
import Homepage from './views/Homepage';

function App() {
  const { isLoading } = useUser();
  if (isLoading) return <Loader type={LoaderType.fullScreen} />;
  localStorage.removeItem('social');

  return isLoading ? (
    <Loader type={LoaderType.fullScreen} />
  ) : (
    <UserContextProvider>
      <Routes>
        <Route path='/oauth/:social/redirect' element={<HandleSocials />} />
        <Route path='/' element={<Homepage />} />
      </Routes>
      <ErrorHandling />
    </UserContextProvider>
  );
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN!,
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // }),
    // new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: import.meta.env.VITE_ENVIRONMENT,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Suspense fallback={<Loader type={LoaderType.fullScreen} />}>
      <ErrorProvider>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID!}>
          <App />
        </GoogleOAuthProvider>
      </ErrorProvider>
    </Suspense>
  </BrowserRouter>,
);
