import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  hermesOAuthHelpURL,
  hermesOAuthPrivacyURL,
  hermesOAuthTermsURL,
} from '../utils/URLUtils';

const PopupPage: React.FC<{
  title?: string;
  children: React.ReactNode;
  marginBottom?: boolean;
}> = ({ title, children, marginBottom = true }) => {
  const { t } = useTranslation(['common', 'enumerations']);

  return (
    <div className='bg-gray-extralight10 h-screen m-auto md:flex md:items-center md:justify-center md:w-1/2 pt-4 max-w-xl'>
      <div
        className={
          'popup-inside bg-white h-full rounded-xl shadow-lg flex flex-col justify-between md:items-center z-50 w-full md:w-110'
        }
      >
        <div className='w-full md:py-4 px-2'>
          <div className='flex justify-start items-center mb-3'>
            <img
              className='w-38 mr-5'
              src='/images/HermesLogoHorizontal.svg'
              alt='Hermes Protocol logotype'
            />
            {title && (
              <h1 className='text-base text-gray-darksmsolid'>{title}</h1>
            )}
          </div>
          <hr className='mx-3' />
        </div>
        {children}
        <div
          className={classNames('w-full px-4', {
            'mt-10': marginBottom === true,
          })}
        >
          <div className='border-t flex justify-between py-2.5 text-xs text-gray-darksmsolid'>
            <div>{t('languages.en_en', { ns: 'enumerations' })}</div>
            <div className='flex justify-end'>
              <a className='ml-4' href={hermesOAuthHelpURL} target='_blank'>
                {t('helpers.help', { ns: 'common' })}
              </a>
              <a className='ml-4' href={hermesOAuthPrivacyURL} target='_blank'>
                {t('helpers.privacy', { ns: 'common' })}
              </a>
              <a className='ml-4' href={hermesOAuthTermsURL} target='_blank'>
                {t('helpers.terms', { ns: 'common' })}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopupPage;
