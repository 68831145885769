import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { api } from '../shared';
import { UserData } from '../types';

interface IUserContext {
  userData: UserData | null;
  setUser(user?: UserData | null, isLoggedInStatus?: boolean): void;
  getUser(): void;
  isLoggedIn: boolean | null;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | null>>;
  isLoading: boolean | null;
  setIsLoading: Dispatch<SetStateAction<boolean | null>>;
  login: (data: any) => Promise<any>;
  logout: () => void;
}

const UserContext = createContext<IUserContext>({
  userData: null,
  isLoggedIn: null,
  setUser: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  getUser: () => {
    throw 'Requesting component not nested inside UserContextProvider';
  },
  setIsLoggedIn: () => {
    throw 'Requesting component not nested inside UserContext';
  },
  isLoading: false,
  setIsLoading: () => {
    throw 'Requesting component not nested inside UserContext';
  },
  login: () => {
    throw 'Requesting component not nested inside UserContext';
  },
  logout: () => {
    throw 'Requesting component not nested inside UserContext';
  },
});

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const { t } = useTranslation(['enumerations']);
  // const { setError } = useErrorContext();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [userData, setUserData] = useState<UserData | null>(null);

  const setUser = (user: UserData | null, isLoggedInStatus?: boolean) => {
    setUserData(user);
    setIsLoggedIn(isLoggedInStatus || isLoggedIn);
  };

  const getUser = () => {
    if (userData) return;

    api.auth
      .isLoggedIn()
      .then((response) => {
        if (response?.error) {
          // commented for now, waiting api fix
          // setError(
          //   response?.error,
          //   t("errorHandling.userDataError", { ns: "enumerations" })
          // )
          console.log('Error: ', response.error);
          setIsLoggedIn(false);
          return;
        }
        setUserData(response);
        setIsLoggedIn(true);
        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const login = async (data: any) => {
    const response = await api.auth.login(data);
    if (response.error) setIsLoggedIn(false);
    else setIsLoggedIn(true);
    return response;
  };

  const logout = async () => {
    const response = await api.auth.logout();
    if (response) {
      setIsLoggedIn(false);
      setUserData(null);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        isLoggedIn,
        isLoading,
        setIsLoggedIn,
        setIsLoading,
        setUser,
        getUser,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
export default UserContext;
