import React from 'react';
import classNames from 'classnames';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
}
export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  square = 'square',
  custom = 'custom',
}

export enum ButtonJustify {
  start = 'start',
  center = 'center',
  end = 'end',
}

const Button: React.FC<{
  onClick?: React.MouseEventHandler;
  children: React.ReactNode;
  dataId?: string;
  type?: ButtonType;
  size?: ButtonSize;
  justify?: ButtonJustify;
  className?: string;
  alt?: string;
  disabled?: boolean | undefined;
}> = ({
  onClick,
  children,
  type = ButtonType.primary,
  size = ButtonSize.medium,
  justify = ButtonJustify.start,
  className,
  alt = '',
  disabled = false,
}) => {
  return (
    <div className='btn-wrapper relative'>
      <button
        title={alt}
        onClick={onClick}
        className={classNames(
          { 'bg-gold text-white text-bold': type === ButtonType.primary },
          { 'bg-gray-extralight20 text-black': type === ButtonType.secondary },
          { 'py-1 px-3 rounded-10px w-full': size === ButtonSize.small },
          { 'py-3 px-4 rounded-8px w-full': size === ButtonSize.medium },
          { 'py-1 px-2 rounded-8px btn-square': size === ButtonSize.square },
          { 'justify-start': justify === ButtonJustify.start },
          { 'justify-center': justify === ButtonJustify.center },
          { 'justify-end': justify === ButtonJustify.end },
          className,
          {
            'cursor-not-allowed opacity-60': disabled,
          },
          {
            'hover:shadow-lg focus:shadow-lg': !disabled,
          },
        )}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};
export default Button;
