import Button, { ButtonJustify, ButtonSize, ButtonType } from '../Button';

type Props = {
  wallet: typeof import('../../assets/wallets.json')[0];
  onClick?: () => void;
  children: React.ReactNode;
};

const EachConnect = ({ wallet, onClick, children }: Props) => (
  <Button
    type={ButtonType.secondary}
    size={ButtonSize.medium}
    justify={ButtonJustify.start}
    onClick={onClick}
    className='mb-4'
    key={wallet.name}
  >
    <div className='flex items-center'>
      <img
        src={wallet.image}
        className={'inline w-6'}
        alt={`${wallet.name} Wallet`}
      />
      <span className='pl-9'>{children}</span>
    </div>
  </Button>
);

export default EachConnect;
