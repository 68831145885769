import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import discord from '../assets/images/discord_white.svg';
import LoginGoogle from '../components/LoginGoogle';
import Popup from '../components/PopupPage';
import TelegramLoginButton, {
  TelegramUser,
} from '../components/TelegramLoginButton';
import { useUser } from '../context/user.context';
import { origin } from '../shared';
import { getDiscordURL, getProjectURL } from '../utils/';

const SocialAuthorization = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const { setIsLoading } = useUser();

  useEffect(() => setIsLoading(false), []);

  return (
    <Popup title={t('social_authorization', { ns: 'common' })}>
      <div className='px-10'>
        <div className='mt-4 mb-8 flex flex-col items-center'>
          {/* {businessPlaceholder.image && (
            <img
              className="mb-4 h-10"
              src={businessPlaceholder.image}
              alt={businessPlaceholder.name}
            />
          )} */}
          <h2 className='text-xl font-medium text-gray-dark mb-1'>
            {t('choose_social', { ns: 'common' })}
          </h2>
          <h3 className='mt-1 text-center'>
            {t('toContinue', { ns: 'common' })}
            <Link
              to={getProjectURL() ?? '/'}
              className='ml-2 font-medium text-gold'
            >
              {t(`projects.${origin}`, { ns: 'common' })}
            </Link>
          </h3>
          <div className='mt-8 m-auto w-52'>
            <button
              className='py-2 px-4 rounded-12px bg-discord text-white w-full mb-4'
              onClick={() => (window.location.href = getDiscordURL())}
            >
              <img src={discord} className={'inline w-6'} alt='Discord icon' />
              <span className='pl-3 md:pl-4'>
                {t('social.discord', { ns: 'common' })}
              </span>
            </button>

            <button>
              <TelegramLoginButton
                botName={import.meta.env.VITE_TELEGRAM_BOT_NAME || ''}
                buttonSize='large'
                cornerRadius={12}
                requestAccess={true}
                usePic={true}
                lang='en'
                dataOnAuth={(user: TelegramUser) => {
                  const url = new URL(
                    '/oauth/telegram/redirect',
                    window.location.origin,
                  );
                  Object.entries(user).map((v) => {
                    url.searchParams.append(v[0], v[1]);
                  });
                  window.location.href = (url.pathname + url.search).toString();
                }}
              />
            </button>
            {origin === 'business' && <LoginGoogle />}
          </div>
        </div>
      </div>
      {getProjectURL() && (
        <div className='w-3/4 px-10 text-sm mt-6 text-gray-extralight90 text-center mx-auto'>
          <span>{t('once_you_authorize', { ns: 'common' })}</span>
          <Link to={getProjectURL()!} className='ml-2 text-gold underline'>
            {getProjectURL()}
          </Link>
        </div>
      )}
    </Popup>
  );
};

export default SocialAuthorization;
