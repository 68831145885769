import { HTTPMethods } from './enums';

export enum LoginStep {
  connectWallet = 'connectWallet',
  verifyWallet = 'verifyWallet',
}

export enum ModalState {
  verify = 'verify',
  success = 'success',
  failure = 'failure',
  hasUser = 'hasUser',
  noUser = 'noUser',
  noSocial = 'noSocial',
  mfa = 'mfa',
  mfaFailure = 'mfaFailure',
  mfaValidate = 'mfaValidate',
  guestUser = 'guestUser',
}

export enum AuthFlow {
  signup = 'signup',
  login = 'login',
}

export enum BlockchainType {
  TerraClassic = 'zb1_001',
  Terrav2 = 'zb1_002',
  Near = 'zb1_003',
  Radix = 'zb1_005',
  CosmosHub = 'zb1_997',
  EVM = 'zb1_998',
  Unknown = 'zb1_999',
}

export enum WalletType {
  Terra = 'Terra',
  Z3us = 'Z3us',
}
export interface Wallet {
  walletAddress: string;
  blockchain: BlockchainType;
  originalBytes: string;
  signedBytes: string | any;
}
export interface User {
  alias: string;
  image: string;
}

export interface RequestConfig {
  method: HTTPMethods;
  headers?: any;
  body?: any;
}

export interface UserData {
  userId: string;
  isLoggedIn: boolean;
  jwtToken: string | null;
  mfaEnabled?: boolean;
  lastLogIn: number;
  userStatus: number;
  activeDate: number;
  clientAlias: string;
  userImage: string;
  noSocialsPopup: boolean;
  noBrandNamePopup: boolean;
  defaultSocial: string;
  socials: UserSocialData[] | null;
  wallets: {
    walletAddress: string;
    walletAlias: string;
    chainId: string;
  }[];
  notifications: [] | null;
  alertHistory: [] | null;
  clientId?: string[];
  clients?: Clients[];
}
export interface UserSocialData {
  type: string;
  username: string;
  status: number;
  id: string;
}

export interface Clients {
  clientId: string;
  companyName?: string | undefined;
  brandName: string;
  website?: string | undefined;
  imageUrl?: string | undefined;
  termsService?: string | undefined;
  privacyPolicy?: string | undefined;
  address?: Address | undefined;
  vat?: string | undefined;
  billingContact?: string | undefined;
  plan: string;
  status: number;
  activeDate: number;
  inactiveDate?: number | undefined;
  contactLists?: [] | undefined;
  campaigns?: [] | undefined;
}
interface Address {
  street1: string;
  street2: string;
  postalCode: string;
  city: string;
  country: string;
}

export type Social =
  | {
      social: 'discord';
      socialId: string;
      username: string;
      token: string;
      expiration: number;
      refreshToken: string;
    }
  | {
      social: 'telegram';
      socialId: string;
      username: string;
      hash: string;
      url: string;
    }
  | {
      social: 'google';
      socialId: string;
      email: string;
      username: string;
    };
