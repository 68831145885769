import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';

import google from '../assets/images/google.png';
import Button, { ButtonSize, ButtonType } from './Button';

const LoginGoogle: React.FC<{}> = () => {
  const { t } = useTranslation(['common', 'enumerations']);
  const [user, setUser] = useState<any>(undefined);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    (async () => {
      if (user) {
        fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          },
        )
          .then(async (res: any) => {
            const data = await res.json();

            const pathAppend = Object.entries({
              email: data.email,
              id: data.id,
              name: data.name,
            })
              .map((a) => `${a[0]}=${a[1]}`)
              .join('&');

            const url = new URL(
              '/oauth/google/redirect',
              window.location.origin,
            );
            navigate(`${url.pathname}?${pathAppend}`);
          })
          .catch((err) => console.error('err', err));
      }
    })();
  }, [user]);

  return (
    <>
      <Button
        onClick={() => login()}
        type={ButtonType.secondary}
        size={ButtonSize.custom}
        className={'py-2 px-4 rounded-12px w-full'}
      >
        <img
          src={google}
          className={classNames('inline w-6')}
          alt='Google icon'
        />
        <span className='pl-3 md:pl-5'>
          {t(`social.google`, { ns: 'enumerations' })}
        </span>
      </Button>
    </>
  );
};
export default LoginGoogle;
