import { AuthService } from '../services/auth.service';
import { SocialService } from '../services/social.service';
import { UserService } from '../services/user.service';
import { WalletService } from '../services/wallet.service';

const params = new URLSearchParams(window.location.search);

export const origin =
  params.get('origin') ?? localStorage.getItem('origin') ?? 'webapp';
localStorage.setItem('origin', origin);

export namespace api {
  export const auth = new AuthService();
  export const social = new SocialService();
  export const user = new UserService();
  export const wallet = new WalletService();
}
