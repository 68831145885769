import { APIService } from './api.service';

export class SocialService extends APIService {
  constructor() {
    super();
  }

  public async addSocial(data: any, social: string): Promise<any> {
    return await this.post<any>(`/fauna/social/${social}/${this.origin}`, data);
  }

  public async getSocial(data: any, social: string) {
    return await this.post<any>(`/oauth/${social}`, data);
  }
}
