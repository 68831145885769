export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum Action {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
}

export enum TokenType {
  REFRESH = 'REFRESH',
  ACCESS = 'ACCESS',
}

export enum BlockchainImage {
  zb1_001 = 'https://assets.hermesprotocol.io/img/projects/TerraClassic.png',
  zb1_002 = 'https://assets.hermesprotocol.io/img/projects/Terra.png',
  zb1_003 = 'https://images2.imgbox.com/19/47/UFVV7X0B_o.png',
  zb1_005 = 'https://assets-global.website-files.com/6053f7fca5bf627283b582c2/6266da249c0c7cd4101e952a_Radix-Icon-400x400.png',
  zb1_998 = 'https://i.imgur.com/TCNtqcK.png'
}

