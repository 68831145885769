import {
  DataRequestBuilder,
  RadixDappToolkit,
} from '@radixdlt/radix-dapp-toolkit';
import { BlockchainType, Wallet } from '../../types';

import { useEffect } from 'react';
import { api } from '../../shared';

type Props = {
  setWallet(wallet: Wallet): void;
};

const RadixConnect = ({ setWallet }: Props) => {
  useEffect(() => {
    const rdt = RadixDappToolkit({
      dAppDefinitionAddress: import.meta.env.VITE_RADIX_DAPP_DEFINITION,
      networkId: +import.meta.env.VITE_RADIX_NETWORK_ID,
      applicationName: 'Hermes Protocol STG',
    });

    rdt.walletApi.setRequestData(
      DataRequestBuilder.accounts().exactly(1).withProof(),
    );

    rdt.walletApi.provideChallengeGenerator(
      async () => await api.auth.bytesRadix(),
    );

    rdt.walletApi.dataRequestControl(async ({ proofs }) => {
      setWallet({
        blockchain: BlockchainType.Radix,
        originalBytes: proofs[0].challenge,
        walletAddress: proofs[0].address,
        signedBytes: proofs[0],
      });

      rdt.disconnect();
    });

    return () => rdt.destroy();
  }, []);

  return (
    <div className='mb-2'>
      {/* @ts-ignore */}
      <radix-connect-button />
    </div>
  );
};

export default RadixConnect;
