// @ts-ignore
// import-sort-ignore
import { randomBytes } from 'crypto-browserify';

import { Cookies } from 'react-cookie';

const searchParams = new URLSearchParams(document.location.search);
const origin =
  searchParams.get('origin') ?? localStorage.getItem('origin') ?? 'webapp';

export const getShorterWalletAddress = (walletAddress: string) => {
  return walletAddress.slice(0, 10) + '...' + walletAddress.slice(-4);
};

export const redirect = (refreshToken?: string) => {
  window.location.replace(
    `${
      origin === 'webapp'
        ? import.meta.env.VITE_WEBAPP_URL
        : import.meta.env.VITE_BUSINESS_URL
    }${refreshToken ? `?refreshToken=${refreshToken}` : ''}`,
  );
};

export const resetLocalStorage = () => {
  localStorage.removeItem('origin');
  localStorage.removeItem('action');
  localStorage.removeItem('telegramOAuth');
  localStorage.removeItem('social');
};

export const getCookies = () => {
  const cookies = new Cookies();

  const accessCookie: string = `accessToken${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  const refreshCookie: string = `refreshToken${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  return {
    accessToken: cookies.get(accessCookie),
    refreshToken: cookies.get(refreshCookie),
  };
};

export const setCookies = (cookieName: string, cookieValue: string) => {
  const cookies = new Cookies();

  const cookie: string = `${cookieName}${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  cookies.set(cookie, cookieValue, {
    path: '/',
    domain: import.meta.env.VITE_ORIGIN,
    secure: true,
  });
};

export const removeCookie = (cookieName: string) => {
  const cookies = new Cookies();

  const cookie: string = `${cookieName}${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  cookies.remove(cookie);
};

export const removeCookies = () => {
  const cookies = new Cookies();

  const accessCookie: string = `accessToken${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  const refreshCookie: string = `refreshToken${
    origin[0].toUpperCase() + origin.slice(1)
  }`;

  cookies.remove(accessCookie);
  cookies.remove(refreshCookie);
};

const stringB64 = (str: any) => {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const getDiscordURL = () => {
  let state = stringB64(randomBytes(32));
  sessionStorage.setItem('state_check', JSON.stringify(state));
  const reqData = new URLSearchParams();
  reqData.append('client_id', import.meta.env.VITE_DISCORD_ID!);
  reqData.append('redirect_uri', import.meta.env.VITE_DISCORD_REDIRECT_URL!);
  reqData.append('response_type', 'code');
  reqData.append('scope', 'identify');
  reqData.append('state', state);
  return 'https://discord.com/api/oauth2/authorize?' + reqData;
};

export const getProjectURL = () => {
  const origin: string = localStorage.getItem('origin') ?? 'webapp';
  return origin === 'business'
    ? import.meta.env.VITE_BUSINESS_URL
    : import.meta.env.VITE_WEBAPP_URL;
};
